.App{
float:left;
width:100%;
max-width:100%;
overflow:hidden;
margin:0;
padding:0;
}

.wrapper {
  margin:0;padding:0;width:100%;
margin-top:100px;
}

.nav-links{
margin-top:30px;
padding:0px 0px 10px 5px;
color:#04AA6D;
font-size:20px;
padding-right: min(5px);
}

body h3 {
  margin-bottom: 25px;
}

.navbar-brand a {
  color: #04AA6D;
}

.navadmin{ 
float:left;
display:block;
width:100%;
height:72px;
background-color:#333; 
}

.form-wrapper,
.table-wrapper { 
  max-width: 1020px;
  margin: 0;
  padding:0;
margin-left:auto;
margin-right:auto;
}

.table-wrapper {
  max-width: 1020px;
}

.edit-link {
  padding: 5px 5px;
  font-size: 0.875rem;
  line-height: normal;
  border-radius: 0.2rem;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.edit-link:hover {
  text-decoration: none;
  color: #ffffff;
}

.delete{ float:right;}

.product-table-row{
  float:left;
  width:100%;
  background-color:blue;
}

.footer a:link, .footer :visited, .fotter a:hover, .footer a:active{
  color:#fff;
}